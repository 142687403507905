import Vue from 'vue'
import App from './App.vue'
import "./common/css/common.css"
import './common/css/animate.css'

// 引入router
import router from './router'

Vue.config.productionTip = false

new Vue({
    render: h => h(App),
    router
}).$mount('#app')