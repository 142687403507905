<template>
  <div>
    <headerTop :path="'/news'" title="新闻资讯"/>

    <!-- 通用banner -->
    <div class="com_ban">
      <img src="../../common/images/icon14.jpg" class="wow fadeIn" style="visibility: visible; animation-name: fadeIn;">
      <div class="container">
          <p class="wow fadeInUp" style="visibility: visible; animation-name: fadeInUp;">新闻资讯</p>
          <p class="wow fadeInUp" data-wow-delay=".5s" style="visibility: visible; animation-delay: 0.5s; animation-name: fadeInUp;"></p>
          <ul>           
              <li :class="{b_active: show==0}" @click="goTo(0)" class="wow fadeInUp" data-wow-delay="0s" style="visibility: visible; animation-delay: 0s; animation-name: fadeInUp;"><a href="#">待添加</a></li>
          </ul>
      </div>
    </div>

    <!-- 小导航 -->
    <div class="snav">
      <div class="container  wow fadeInUp" style="visibility: visible; animation-name: fadeInUp;">
        <img src="../../common/images/icon11.png">
        <ul>
          <a href="#">首页</a> &gt;&gt; 
          <a href="#" title="新闻资讯">新闻资讯</a> 
        </ul>
      </div>
    </div>

    <div class="newsmain">
      <div class="container">
        <ul class="newslist">
          <li>
            <a href="#">
              <p class="time">12/10<span>2022</span></p>
              <img src="../../common/images/icon15.jpg">
              <p> 宁义忠带队企业家考察团考察泸州江阳区</p>
            </a>
          </li>
          <li>
            <a href="#">
              <p class="time">12/10<span>2022</span></p>
              <img src="../../common/images/icon15.jpg">
              <p> 宁义忠带队企业家考察团考察泸州江阳区</p>
            </a>
          </li>
          <li>
            <a href="#">
              <p class="time">12/10<span>2022</span></p>
              <img src="../../common/images/icon15.jpg">
              <p> 宁义忠带队企业家考察团考察泸州江阳区</p>
            </a>
          </li>
          <li>
            <a href="#">
              <p class="time">12/10<span>2022</span></p>
              <img src="../../common/images/icon15.jpg">
              <p> 宁义忠带队企业家考察团考察泸州江阳区</p>
            </a>
          </li>
          <li>
            <a href="#">
              <p class="time">12/10<span>2022</span></p>
              <img src="../../common/images/icon15.jpg">
              <p> 宁义忠带队企业家考察团考察泸州江阳区</p>
            </a>
          </li>
          <li>
            <a href="#">
              <p class="time">12/10<span>2022</span></p>
              <img src="../../common/images/icon15.jpg">
              <p> 宁义忠带队企业家考察团考察泸州江阳区</p>
            </a>
          </li>
        </ul>
        <div class="listpage"></div>
      </div>
    </div>


    <footerBottom/>
  </div>
</template>

<script>
  import headerTop from '../../components/headerTop/headerTop.vue'
  import footerBottom from '../../components/footerBottom/footerBottom.vue'
  export default {
    components:{
      headerTop,
      footerBottom
    },
    data() {
      return {
        show:0
      }
    },
    methods: {
      goTo(index){
        if(index==0){
          this.show=0
        }
      }
    },
  }
</script>

<style scoped>
  @import './news.css';
</style>