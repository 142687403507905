<template>
  <div>
    <headerTop :path="'/service'" title="服务案例"/>

    <!-- 通用banner -->
    <div class="com_ban">
      <img src="../../common/images/icon13.jpg" class="wow fadeIn" style="visibility: visible;">
      <div class="container">
        <p class="wow fadeInUp" style="visibility: visible;">服务案例</p>
        <p class="wow fadeInUp" data-wow-delay=".5s" style="visibility: visible; animation-delay: 0.5s;"></p>
        <ul>          
          <li :class="{b_active: show==0}" @click="goTo(0)" class="wow fadeInUp" data-wow-delay="0s" style="visibility: visible; animation-delay: 0s;"><a href="#">即将上线</a></li> 
        </ul>
      </div>
    </div>


    <footerBottom/>
  </div>
</template>

<script>
  import headerTop from '../../components/headerTop/headerTop.vue'
  import footerBottom from '../../components/footerBottom/footerBottom.vue'
  export default {
    components:{
      headerTop,
      footerBottom
    },
    data() {
      return {
        show:0
      }
    },

    methods: {
      goTo(index){
        if(index==0){
          this.show=0
        }
      }
    },
  }
</script>

<style>

</style>