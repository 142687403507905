<template>
  <div>
    <!-- 头部导航栏 -->
    <headerTop :path="'/productCenter'" title="产品中心"/>

    <!-- <div class="headerTop">
      <div class="logo">
        <a href="#">
          <img src="../../common/images/logo.png" alt="珠宝玉石首饰国检集团深圳研究所有限公司" srcset="">
        </a>
      </div>
      <div class="rightNav">
        <ul>
          <li @click="goto(path)">
            <a  href="#" :class="{active: isCurrent(path)}" class="first">{{title}}</a>
            <span></span>
          </li>

          <li @click="goto('/home')" >
            <a class="first"  href="#">首页</a>
            <span></span>
          </li>
          <li @click="goto('/productCenter')" >
            <a class="first" :class="{active: isCurrent('/productCenter')}"  href="#">产品中心</a>
            <div class="dropitempc" style="height: 140px; padding-top: 10px; margin-top: 0px; padding-bottom: 0px; margin-bottom: 0px;">
              <ul>
                <li><a href="#">智慧硬件</a></li>
                <li><a href="#">软件系统</a></li>
                <li><a href="#">服务赋能</a></li>
              </ul>
            </div>
            <span></span>
          </li>
          <li @click="goto2('/solution')" >
            <a class="first" href="#">解决方案</a>
            <span></span>
            <div class="dropitempc" style="height: 224px; padding-top: 10px; margin-top: 0px; padding-bottom: 0px; margin-bottom: 0px;">
              <ul>
                <li><a href="#">智慧珠宝检测</a></li>
                <li><a href="#">珠宝智慧零售</a></li>
                <li><a href="#">智慧展厅</a></li>
                <li><a href="#">智慧工厂</a></li>
                <li><a href="#">智慧黄金回收</a></li>
              </ul>
            </div>
          </li>
          <li @click="goto3('/service')" >
            <a class="first" href="#">服务案例</a>
            <span></span>
            <div class="dropitempc" style="height: 56px; padding-top: 10px; margin-top: 0px; padding-bottom: 0px; margin-bottom: 0px;">
              <ul>
                <li><a href="#">即将上线</a></li>
              </ul>
            </div>
          </li>
          <li @click="goto4('/news')" >
            <a class="first" href="#">新闻资讯</a>
            <span></span>
            <div class="dropitempc" style="height: 56px; padding-top: 10px; margin-top: 0px; padding-bottom: 0px; margin-bottom: 0px;">
              <ul>
                <li><a href="#">待添加</a></li>
              </ul>
            </div>
          </li>
          <li @click="goto5('/aboutUs')" >
            <a class="first" href="#">关于我们</a>
            <div class="dropitempc" style="height: 56px; padding-top: 10px; margin-top: 0px; padding-bottom: 0px; margin-bottom: 0px;">
              <ul>
                <li><a href="#">公司介绍</a></li>
              </ul>
            </div>
          </li>
          
        </ul>
      </div>
    </div> -->



    <!-- 通用banner -->
    <div class="com_ban">
      <img src="../../common/images/icon10.png" class="wow fadeIn" style="visibility: visible; animation-name: fadeIn;">
      <div class="container">
          <p class="wow fadeInUp" style="visibility: visible; animation-name: fadeInUp;">产品中心</p>
          <p class="wow fadeInUp" data-wow-delay=".5s" style="visibility: visible; animation-delay: 0.5s; animation-name: fadeInUp;"></p>
          <ul>            
              <li :class="{b_active: show==0}" @click="goTo(0)" class="wow fadeInUp" data-wow-delay="0s" style="visibility: visible; animation-delay: 0s; animation-name: fadeInUp;"><a href="#">智慧硬件</a></li>
              <li :class="{b_active: show==1}" @click="goTo(1)" class="wow fadeInUp" data-wow-delay="0s" style="visibility: visible; animation-delay: 0s; animation-name: fadeInUp;"><a href="#">软件系统</a></li>        
              <li :class="{b_active: show==2}" @click="goTo(2)" class="wow fadeInUp" data-wow-delay="0s" style="visibility: visible; animation-delay: 0s; animation-name: fadeInUp;"><a href="#">服务赋能</a></li>    
          </ul>
      </div>
    </div>

    <!-- 小导航 -->
    <div class="snav">
      <div class="container  wow fadeInUp" style="visibility: visible; animation-name: fadeInUp;">
        <img src="../../common/images/icon11.png">
          <ul>
            <a href="#">首页</a> &gt;&gt;
            <a href="#" title="产品中心">产品中心</a>&gt;&gt;&nbsp;&nbsp;
            <a href="#" title="智慧硬件">智慧硬件</a>
          </ul>
      </div>
    </div>

    <!-- 智慧园区管理服务 -->
    
    <div class="p1lamp" v-if="show==0">
      <div class="container">
        <p class="wow fadeInUp" style="visibility: visible; animation-name: fadeInUp;">智慧硬件</p>
        <p class="wow fadeInUp longp" data-wow-delay=".2s" style="visibility: visible; animation-delay: 0.2s; animation-name: fadeInUp;"></p>
        <table style="width:100%;height:50%;" cellpadding="2" cellspacing="0" border="1" bordercolor="#000000">
          <!-- 智慧硬件 --> 
          <tbody>
            <tr>
              <td>
                宝石元素含量分析仪
              </td>
              <td>
                差分拉曼光谱仪
              </td>
              <td>
                称重照相一体机
              </td>
              <td>
                多功能发光光谱仪
              </td>
              <td>
                钻石鉴定仪
              </td>
              <td>
                钻石心箭效应观察仪
              </td>
              <td>
                红宝石首饰观察鉴定仪
              </td>
              <td>
                钻石内部特征采集仪
              </td>
            </tr>
            <tr>
              <td>
                跨楼层AGV<br>
              </td>
              <td>
                智能分拣<br>
              </td>
              <td>
                智能/批量称重<br>
              </td>
              <td>
                智能挂签<br>
              </td>
              <td>
                智能收发机器人<br>
              </td>
              <td>
                自动检测<br>
              </td>
              <td>
                自动传送<br>
              </td>
              <td>
                APP、小程序3D选款平台<br>
              </td>
            </tr>
            <tr>
              <td>
                贵金属型材自动化生产系统<br>
              </td>
              <td>
                宝石自动化切割打磨系统<br>
              </td>
              <td>
                创意设计智能加工中心<br>
              </td>
              <td>
                大师工作室专用新工具套装<br>
              </td>
              <td>
                自动化生产线定制系统<br>
              </td>
              <td>
                亮寻式版房<br>
              </td>
              <td>
                智能排产<br>
              </td>
              <td>
                智能仓储<br>
              </td>
            </tr>
            <tr>
              <td>
                自动/批量称重智能机器<br>
              </td>
              <td>
                自动编号机<br>
              </td>
              <td>
                自动封装机<br>
              </td>
              <td>
                隧道分拣机<br>
              </td>
              <td>
                检测服务终端机<br>
              </td>
              <td>
                智慧物流下游分发<br>
              </td>
              <td>
                自动称重<br>
              </td>
              <td>
                <p>
                  &nbsp;
                </p>
              </td>
            </tr>
          </tbody>

          <!-- 软件系统 -->
          <!-- <tbody>
            <tr>
              <td>
                虚拟直播
              </td>
              <td>
                BI大屏
              </td>
              <td>
                货品出库到门店安全监管技术<br>
              </td>
              <td>
                企业数据中台<br>
              </td>
              <td>
                黄金珠宝设计过程管理软件<br>
              </td>
              <td>
                手绘转3D装备<br>
              </td>
              <td>
                创意设计共享行业平台<br>
              </td>
              <td>
                款式生产指导大数据服务<br>
              </td>
            </tr>
            <tr>
              <td>
                智能排产软件服务<br>
              </td>
              <td>
                款式智能化识别软件和设备<br>
              </td>
              <td>
                贷金生产数字化监管方案<br>
              </td>
              <td>
                拍照自动展示产品信息、客众、版权等<br>
              </td>
              <td>
                移动跟踪订单<br>
              </td>
              <td>
                珠宝制造协同办公OA<br>
              </td>
              <td>
                生产管理系统<br>
              </td>
              <td>
                IP共享行业平台<br>
              </td>
            </tr>
            <tr>
              <td>
                人力产出自动评估系统<br>
              </td>
              <td>
                智慧门店系统<br>
              </td>
              <td>
                珠宝零售进销存系统<br>
              </td>
              <td>
                珠宝门店移动导购系统<br>
              </td>
              <td>
                BOSS 手机报表系统<br>
              </td>
              <td>
                微信会员营销系统<br>
              </td>
              <td>
                珠宝镶嵌批发管理系统<br>
              </td>
              <td>
                珠宝素金批发管理系统<br>
              </td>
            </tr>
            <tr>
              <td>
                钻石批发管理系统<br>
              </td>
              <td>
                珠宝版房网络订单云系统<br>
              </td>
              <td>
                珠宝工厂MES系统<br>
              </td>
              <td>
                B2C电商中台<br>
              </td>
              <td>
                电商与线下进销存对接中台系统<br>
              </td>
              <td>
                款式生产指导大数据SAAS<br>
              </td>
              <td>
                款式智能化识别<br>
              </td>
              <td>
                珠宝制造协同办公系统<br>
              </td>
            </tr>
            <tr>
              <td>
                制造智能管理系统<br>
              </td>
              <td>
                预算费用控制系统<br>
              </td>
              <td>
                <br>
              </td>
              <td>
                <br>
              </td>
              <td>
                <br>
              </td>
              <td>
                <br>
              </td>
              <td>
                <br>
              </td>
              <td>
                <br>
              </td>
            </tr>
          </tbody> -->

          <!-- 服务赋能 -->
          <!-- <tbody>
            <tr>
              <td>
                <span>检测培训</span>
              </td>
              <td>
                <span>实验室规划与设计</span>
              </td>
              <td>
                <span>检测系统规划</span>
              </td>
              <td>
                <span>电商建站和优化服务</span>
              </td>
              <td>
                <span>创意设计供需对接<br>
                </span>
              </td>
              <td>
                <span>端到云到端的一站式短视频解决方案<br>
                </span>
              </td>
            </tr>
          </tbody> -->

        </table>   
      </div>                
    </div>

    <!-- 软件系统 -->
     <div class="p1lamp" v-if="show==1">
      <div class="container">
        <p class="wow fadeInUp" style="visibility: visible; animation-name: fadeInUp;">软件系统</p>
        <p class="wow fadeInUp longp" data-wow-delay=".2s" style="visibility: visible; animation-delay: 0.2s; animation-name: fadeInUp;"></p>
        <table style="width:100%;" cellpadding="2" cellspacing="0" border="1" bordercolor="#000000">
          <tbody>
            <tr>
              <td>
                虚拟直播
              </td>
              <td>
                BI大屏
              </td>
              <td>
                货品出库到门店安全监管技术<br>
              </td>
              <td>
                企业数据中台<br>
              </td>
              <td>
                黄金珠宝设计过程管理软件<br>
              </td>
              <td>
                手绘转3D装备<br>
              </td>
              <td>
                创意设计共享行业平台<br>
              </td>
              <td>
                款式生产指导大数据服务<br>
              </td>
            </tr>
            <tr>
              <td>
                智能排产软件服务<br>
              </td>
              <td>
                款式智能化识别软件和设备<br>
              </td>
              <td>
                贷金生产数字化监管方案<br>
              </td>
              <td>
                拍照自动展示产品信息、客众、版权等<br>
              </td>
              <td>
                移动跟踪订单<br>
              </td>
              <td>
                珠宝制造协同办公OA<br>
              </td>
              <td>
                生产管理系统<br>
              </td>
              <td>
                IP共享行业平台<br>
              </td>
            </tr>
            <tr>
              <td>
                人力产出自动评估系统<br>
              </td>
              <td>
                智慧门店系统<br>
              </td>
              <td>
                珠宝零售进销存系统<br>
              </td>
              <td>
                珠宝门店移动导购系统<br>
              </td>
              <td>
                BOSS 手机报表系统<br>
              </td>
              <td>
                微信会员营销系统<br>
              </td>
              <td>
                珠宝镶嵌批发管理系统<br>
              </td>
              <td>
                珠宝素金批发管理系统<br>
              </td>
            </tr>
            <tr>
              <td>
                钻石批发管理系统<br>
              </td>
              <td>
                珠宝版房网络订单云系统<br>
              </td>
              <td>
                珠宝工厂MES系统<br>
              </td>
              <td>
                B2C电商中台<br>
              </td>
              <td>
                电商与线下进销存对接中台系统<br>
              </td>
              <td>
                款式生产指导大数据SAAS<br>
              </td>
              <td>
                款式智能化识别<br>
              </td>
              <td>
                珠宝制造协同办公系统<br>
              </td>
            </tr>
            <tr>
              <td>
                制造智能管理系统<br>
              </td>
              <td>
                预算费用控制系统<br>
              </td>
              <td>
                <br>
              </td>
              <td>
                <br>
              </td>
              <td>
                <br>
              </td>
              <td>
                <br>
              </td>
              <td>
                <br>
              </td>
              <td>
                <br>
              </td>
            </tr>
          </tbody>
        </table> 
      </div>                
    </div>


    <!-- 服务赋能 -->
   <div class="p1lamp" v-if="show==2">
      <div class="container">
        <p class="wow fadeInUp" style="visibility: visible; animation-name: fadeInUp;">服务赋能</p>
        <p class="wow fadeInUp longp" data-wow-delay=".2s" style="visibility: visible; animation-delay: 0.2s; animation-name: fadeInUp;"></p>
       <table style="width:100%;" cellpadding="2" cellspacing="0" border="1" bordercolor="#000000">
          <tbody>
            <tr>
              <td>
                <span>检测培训</span>
              </td>
              <td>
                <span>实验室规划与设计</span>
              </td>
              <td>
                <span>检测系统规划</span>
              </td>
              <td>
                <span>电商建站和优化服务</span>
              </td>
              <td>
                <span>创意设计供需对接<br>
                </span>
              </td>
              <td>
                <span>端到云到端的一站式短视频解决方案<br>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>                
    </div>

    <!-- 核心优势 -->
    <div class="p1adv">
      <div class="container">
          <p class="wow fadeInUp" style="visibility: visible; animation-name: fadeInUp;">核心优势</p>
          <ul>
            <li class="wow fadeInUp" style="visibility: visible; animation-name: fadeInUp;">
              <div class="p1a_show">
                <img src="../../common/images/icon12.png">
                <p> 数字孪生</p>
                <p> 3D精细化建模</p>
              </div>
              <div class="p1a_hide">
                <p>数字孪生</p>
                <p>3D精细化建模</p>
                <p></p>
              </div>
            </li>
            <li class="wow fadeInUp" style="visibility: visible; animation-name: fadeInUp;">
              <div class="p1a_show">
                <img src="../../common/images/icon13.png">
                <p> 快速集成</p>
                <p> 智能物联协同管理</p>
              </div>
              <div class="p1a_hide">
                <p>快速集成</p>
                <p>智能物联协同管理</p>
                <p></p>
              </div>
            </li>
            <li class="wow fadeInUp" style="visibility: visible; animation-name: fadeInUp;">
              <div class="p1a_show">
                <img src="../../common/images/icon14.png">
                <p> 数字运营</p>
                <p> 精细化服务</p>
              </div>
              <div class="p1a_hide">
                <p>数字运营</p>
                <p>精细化服务</p>
                <p></p>
              </div>
            </li>
            <li class="wow fadeInUp" style="visibility: visible; animation-name: fadeInUp;">
              <div class="p1a_show">
                <img src="../../common/images/icon15.png">
                <p> 数字预案</p>
                <p> 智能应急处置</p>
              </div>
              <div class="p1a_hide">
                <p>数字预案</p>
                <p>智能应急处置</p>
                <p></p>
              </div>
            </li>
          </ul>
      </div>
    </div>

    <!-- 应用案例 -->
    <div class="p1exam">
      <div class="container">
        <p class="wow fadeInUp" style="visibility: visible; animation-name: fadeInUp;">应用案例</p>
        <div class="p1emain">
          <a href="#">
            <img class="wow fadeInLeft" src="" style="visibility: visible; animation-name: fadeInLeft;">
            <div class="wow fadeInRight" style="visibility: visible; animation-name: fadeInRight;">
              <p>即将上线</p>
              <p> </p>
              <p>。。。</p>
            </div>
          </a>
        </div>
        <div class="pro1more wow bounceInUp" style="visibility: visible; animation-name: bounceInUp;">
          <a href="#">
            <img src="../../common/images/icon16.png">
            <p style="color:#000;">查看更多案例</p>
          </a>
        </div>
      </div>
    </div>


    <!-- 底部 -->
    <footerBottom/>
  </div>
</template>

<script>
  import footerBottom from '../../components/footerBottom/footerBottom.vue'
  import headerTop from '../../components/headerTop/headerTop.vue'
  export default {
    name: 'ProductCenter',
    components: {
      headerTop,
      footerBottom
    },
    data() {
      return {
        show:0,  //0显示智慧硬件，1显示软件系统，2显示服务赋能
      }
    },
    methods: {

      // 跳转到相应路径
      goto(path) {
        this.$router.replace(path)
        console.log('*******',path);
        },
      // 当前路径
      isCurrent(path) {
        console.log(this.$route.path)
        return this.$route.path === path
      },


      goTo(index){
        if(index == 0){
          this.show = 0
        }
        if(index == 1){
          this.show=1
        }
        if(index==2){
          this.show=2
        }
      }
    },
  }
</script>

<style scoped>
  @import './productCenter.css';
</style>
