<template>
  <div>
     <!-- 头部导航栏部分 -->
    <div class="headerTop">
      <div class="logo">
        <a href="#">
          <img src="../../common/images/logo.png" alt="珠宝玉石首饰国检集团深圳研究所有限公司" srcset="">
        </a>
      </div>
      <div class="rightNav">
        <ul>
          <!-- <li @click="goto(path)">
            <a  href="#" :class="{active: isCurrent(path)}" class="first">{{title}}</a>
            <span></span>
          </li> -->

          
          
          <li @click="goto('/home')" >
            <a class="first" :class="{active: isCurrent('/home')}">首页</a>
            <span></span>
          </li>
          <li @click="goto('/productCenter')" >
            <a class="first" :class="{active: isCurrent('/productCenter')}">产品中心</a>
            <div class="dropitempc" style="height: 140px; padding-top: 10px; margin-top: 0px; padding-bottom: 0px; margin-bottom: 0px;">
              <ul>
                <li><a href="#">智慧硬件</a></li>
                <li><a href="#">软件系统</a></li>
                <li><a href="#">服务赋能</a></li>
              </ul>
            </div>
            <span></span>
          </li>
          <li @click="goto('/solution')" >
            <a class="first" :class="{active: isCurrent('/solution')}">解决方案</a>
            <span></span>
            <div class="dropitempc" style="height: 224px; padding-top: 10px; margin-top: 0px; padding-bottom: 0px; margin-bottom: 0px;">
              <ul>
                <li><a href="#">智慧珠宝检测</a></li>
                <li><a href="#">珠宝智慧零售</a></li>
                <li><a href="#">智慧展厅</a></li>
                <li><a href="#">智慧工厂</a></li>
                <li><a href="#">智慧黄金回收</a></li>
              </ul>
            </div>
          </li>
          <li @click="goto('/service')" >
            <a class="first" :class="{active: isCurrent('/service')}">服务案例</a>
            <span></span>
            <div class="dropitempc" style="height: 56px; padding-top: 10px; margin-top: 0px; padding-bottom: 0px; margin-bottom: 0px;">
              <ul>
                <li><a href="#">即将上线</a></li>
              </ul>
            </div>
          </li>
          <li @click="goto('/news')" >
            <a class="first" :class="{active: isCurrent('/news')}">新闻资讯</a>
            <span></span>
            <div class="dropitempc" style="height: 56px; padding-top: 10px; margin-top: 0px; padding-bottom: 0px; margin-bottom: 0px;">
              <ul>
                <li><a href="#">待添加</a></li>
              </ul>
            </div>
          </li>
          <li @click="goto('/aboutUs')" >
            <a class="first" :class="{active: isCurrent('/aboutUs')}">关于我们</a>
            <div class="dropitempc" style="height: 56px; padding-top: 10px; margin-top: 0px; padding-bottom: 0px; margin-bottom: 0px;">
              <ul>
                <li><a href="#">公司介绍</a></li>
              </ul>
            </div>
          </li>


          

          
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props:['path','title'],
    methods: {
      // 跳转到相应路径
      goto(path) {
        this.$router.replace(path)
        console.log('*******',path);
        },
      // 当前路径
      isCurrent(path) {
        console.log(this.$route.path)
        return this.$route.path === path
      },

      
      }
      
  }
</script>

<style scoped>
  /* 头部导航栏部分  */
 
 .headerTop {
     display: flex;
     justify-content: space-between;
     align-items: center;
     padding: 0 100px;
 }
 
 .rightNav li {
     position: relative;
     padding-right: 10px;
     margin-right: 10px
 }
 
 .rightNav ul {
     display: flex;
 }
 
 .rightNav li span {
     display: block;
     height: 20px;
     width: 1px;
     background-color: #1a1a1a;
     right: 0;
     top: 0;
     bottom: 0;
     position: absolute;
     margin: auto;
 }
 
 .rightNav li a.first {
     display: inline-block;
     padding: 0 30px;
     height: 30px;
     line-height: 30px;
     border-radius: 30px;
     font-size: 15px;
     color: #000;
 }
 
 .active {
     background: linear-gradient(to top, #01B3F3, #416EE1);
     color: #fff !important;
     border-radius: 30px;
     /* margin: 0 20px; */
 }
 .rightNav li:hover a.first{
     background: linear-gradient(to top, #01B3F3, #416EE1);
     color: #fff !important;
 }
 
 .dropitempc {
    position: absolute;
    top: 100%;
    transform: translateX(50%);
    text-align: center;
    right: calc(50% + 5px);
    z-index: 500;
    float: none;
    padding-top: 10px;
    display: none;
}
.dropitempc ul {
    border-radius: 10px;
    display: inline-block;
    overflow: hidden;
    background-color: rgba(0, 0, 0, .5);
}
.dropitempc li {
    width: auto;
    white-space: nowrap;
}
.dropitempc a {
    padding: 5px 15px;
    line-height: 2;
    width: 100%;
    color: #fff;
    display: block;
    font-size: 15px;
}
.rightNav li:hover .dropitempc{
     display: block;
 }
 .dropitempc ul li:hover a{
    background: linear-gradient(to top, #01B3F3, #416EE1);
 }
</style>