<template>
  <div class="homecontainer">
    <!-- 头部导航栏部分 -->
    <headerTop :path="'/home'" title="首页"> </headerTop>
    <!-- <div class="headerTop">
      <div class="logo">
        <a href="#">
          <img src="../../common/images/logo.png" alt="珠宝玉石首饰国检集团深圳研究所有限公司" srcset="">
        </a>
      </div>
      <div class="rightNav">
        <ul>
          <li @click="goto(path)">
            <a  href="#" :class="{active: isCurrent(path)}" class="first">{{title}}</a>
            <span></span>
          </li>

          <li @click="goto('/home')" >
            <a class="first" :class="{active: isCurrent('/home')}"  href="#">首页</a>
            <span></span>
          </li>
          <li @click="goto('/productCenter')" >
            <a class="first" :class="{active: isCurrent('/productCenter')}"  href="#">产品中心</a>
            <div class="dropitempc" style="height: 140px; padding-top: 10px; margin-top: 0px; padding-bottom: 0px; margin-bottom: 0px;">
              <ul>
                <li><a href="#">智慧硬件</a></li>
                <li><a href="#">软件系统</a></li>
                <li><a href="#">服务赋能</a></li>
              </ul>
            </div>
            <span></span>
          </li>
          <li @click="goto('/solution')" >
            <a class="first" :class="{active: isCurrent('/solution')}" href="#">解决方案</a>
            <span></span>
            <div class="dropitempc" style="height: 224px; padding-top: 10px; margin-top: 0px; padding-bottom: 0px; margin-bottom: 0px;">
              <ul>
                <li><a href="#">智慧珠宝检测</a></li>
                <li><a href="#">珠宝智慧零售</a></li>
                <li><a href="#">智慧展厅</a></li>
                <li><a href="#">智慧工厂</a></li>
                <li><a href="#">智慧黄金回收</a></li>
              </ul>
            </div>
          </li>
          <li @click="goto('/service')" >
            <a class="first" :class="{active: isCurrent('/service')}" href="#">服务案例</a>
            <span></span>
            <div class="dropitempc" style="height: 56px; padding-top: 10px; margin-top: 0px; padding-bottom: 0px; margin-bottom: 0px;">
              <ul>
                <li><a href="#">即将上线</a></li>
              </ul>
            </div>
          </li>
          <li @click="goto('/news')" >
            <a class="first" :class="{active: isCurrent('/news')}" href="#">新闻资讯</a>
            <span></span>
            <div class="dropitempc" style="height: 56px; padding-top: 10px; margin-top: 0px; padding-bottom: 0px; margin-bottom: 0px;">
              <ul>
                <li><a href="#">待添加</a></li>
              </ul>
            </div>
          </li>
          <li @click="goto('/aboutUs')" >
            <a class="first" :class="{active: isCurrent('/aboutUs')}" href="#">关于我们</a>
            <div class="dropitempc" style="height: 56px; padding-top: 10px; margin-top: 0px; padding-bottom: 0px; margin-bottom: 0px;">
              <ul>
                <li><a href="#">公司介绍</a></li>
              </ul>
            </div>
          </li>
          
        </ul>
      </div>
    </div> -->


      
   
    <!-- 首页视频 -->
    <div class="video">
      <video id="video" muted="true" preload="true" loop="loop"  autoplay="autoplay">
        <source src="../../common/images/home.mp4" type="video/mp4">
      </video>
      <div class="vtit">
        <p>SaSS云服务平台</p>
        <p>数字化应用实现转型升级</p>		
      </div>
    </div>

    <!--数据驱动 运营赋能  -->
    <div class="data">
      <div class="dataContainer pad">
        <div class="tit">
          <div class="point wow fadeInLeft" style="visibility: visible; animation-name: fadeInLeft;"></div>
          <div class="titp">
            <p class="wow fadeInDown" style="visibility: visible; animation-name: fadeInDown;">数据驱动　运营赋能</p>
            <p class="wow fadeInUp" style="visibility: visible; animation-name: fadeInUp;">DATA DRIVEN OPERATIONCAN ASSIGN</p>
          </div>
          <div class="point wow fadeInRight" style="visibility: visible; animation-name: fadeInRight;"></div>
        </div>

        <!-- 图标 -->
        <div class="dmain">
          <ul>
            <li @mouseenter="enter(isActive)" @mouseleave="leave(isActive)" class="wow fadeInUp" style="visibility: visible; animation-name: fadeInUp;">
              <img v-if="!isActive" src="../../common/images/icon1.png">
              <img v-if="isActive" src="../../common/images/icon1_active.png">
              <p>高效率</p>
              <p>高效率，高质量，为企业打造最高效的智慧云平台</p>
            </li>
            <li @mouseenter="enter1(isActive1)" @mouseleave="leave1(isActive1)" class="wow fadeInUp" style="visibility: visible; animation-name: fadeInUp;">
              <img v-if="!isActive1" src="../../common/images/icon2.png">
              <img v-if="isActive1" src="../../common/images/icon2_active.png">
              <p>强运营</p>
              <p>强大的运营模式，专业的运营团队，认真服务每一天</p>
            </li>
            <li @mouseenter="enter2(isActive2)" @mouseleave="leave2(isActive2)" class="wow fadeInUp" style="visibility: visible; animation-name: fadeInUp;">
              <img v-if="!isActive2" src="../../common/images/icon3.png">
              <img v-if="isActive2" src="../../common/images/icon3_active.png">
              <p>赋能行业</p>
              <p>探索5G、互联网+、珠宝检测、物联网、AI识别等技术</p>
            </li>
          </ul>
			  </div>


      </div>
    </div>

    <!-- 手风琴 -->
    <div class="hand" >
      <ul>
        <li ref="hand" :class="isLong?'achand':''" @mouseenter="changeActive(0)" @mouseleave="closeActive(0)" class="wow bg bounceInRight"  style="visibility: visible; animation-name: bounceInRight; height: 100%;">
          <div class="cover" v-if="!isLong"> 产业云大数据</div>
          <div class="desc" v-if="isLong">
            <p> 产业云大数据平台</p>
            <a href="#">查看详情<img src="../../common/images/icon-6.png"></a>
          </div>
        </li>

        <li class="wow bg1 bounceInRight" :class="isLong1?'achand':''" @mouseenter="changeActive(1)" @mouseleave="closeActive(1)" style="visibility: visible; animation-name: bounceInRight; height: 100%;">
          <div class="cover" v-if="!isLong1"> AI技术</div>
          <div class="desc" v-if="isLong1">
            <p> AI技术应用服务平台</p>
            <a href="#">查看详情<img src="../../common/images/icon-6.png"></a>
          </div>
        </li>

        <li class="wow bg2 bounceInRight" :class="isLong2?'achand':''"  @mouseenter="changeActive(2)" @mouseleave="closeActive(2)" style="visibility: visible; animation-name: bounceInRight; height: 100%;">
          <div class="cover" v-if="!isLong2"> 服务平台</div>
          <div class="desc" v-if="isLong2">
            <p> 智慧园区管理应用服务平台</p>
            <a href="#">查看详情<img src="../../common/images/icon-6.png"></a>
          </div>
        </li>
      </ul>
	  </div>


    <!-- 应用场景 -->
    <div class="screen">
      <div class="pad">
        <div class="tit">
          <div class="point wow fadeInLeft" style="visibility: visible; animation-name: fadeInLeft;"></div>
          <div class="titp">
            <p class="wow fadeInDown" style="visibility: visible; animation-name: fadeInDown;">应用场景</p>
            <p class="wow fadeInUp" style="visibility: visible; animation-name: fadeInUp;">APPLICATION SCENARIOS
            </p>
          </div>
          <div class="point wow fadeInRight" style="visibility: visible; animation-name: fadeInRight;"></div>
        </div>

      <!-- 轮播图部分 -->
      <div class="swiper-container">
        <div class="swiper-container">
            <div class="swiper-wrapper">
              <div class="swiper-slide" v-for="(item,index) in srcList" :key="index"  style="position:relative;overflow:hidden;width:100%;height:500px;">
                <img :src="item.url" alt="">
                <div class="sdesc" style="display: block;">
                  <p> {{item.title}}</p>
                  <p> {{item.content}}</p>
                </div>
              </div>  
            </div>
        </div>
      </div>


       
      </div>
	  </div>

    <!-- 重点服务案例 -->
    <div class="exam">
      <div class="pad">
        <div class="tit">
          <div class="point wow fadeInLeft" style="visibility: visible; animation-name: fadeInLeft;"></div>
          <div class="titp">
            <p class="wow fadeInDown" style="visibility: visible; animation-name: fadeInDown;">重点服务案例</p>
            <p class="wow fadeInUp" style="visibility: visible; animation-name: fadeInUp;">KEY SERVICE CASE</p>
          </div>
          <div class="point wow fadeInRight" style="visibility: visible; animation-name: fadeInRight;"></div>
        </div>
        <div class="examain">
          <ul>
          </ul>
        </div>
        <!-- 加载更多 -->
        <div class="more wow fadeInUp" style="visibility: visible; animation-name: fadeInUp;">
          <a href="#">
            <img src="../../common/images/icon9.png">
            <br>
            <span>查看更多案例</span>
          </a>
        </div>
      </div>
	  </div>

    <!-- 合作伙伴 -->
    <div class="partner" style="padding-bottom:0px;">
      <div class="pad">
        <div class="tit">
          <div class="point wow fadeInLeft" style="visibility: visible; animation-name: fadeInLeft;"></div>
          <div class="titp">
            <p class="wow fadeInDown" style="visibility: visible; animation-name: fadeInDown;">合作伙伴</p>
            <p class="wow fadeInUp" style="visibility: visible; animation-name: fadeInUp;">PARTNERS</p>
          </div>
          <div class="point wow fadeInRight" style="visibility: visible; animation-name: fadeInRight;"></div>
        </div>
        <div class="parmain container">

        </div>
      </div>



	  </div>




    <!-- footer部分 -->
    <footerBottom/>



  </div>
</template> 

<script>
  import Swiper from 'swiper'
  import 'swiper/dist/css/swiper.min.css'
  import headerTop from '../../components/headerTop/headerTop.vue'
  import footerBottom from '../../components/footerBottom/footerBottom.vue'
  export default {
    name: 'Home',
    components: {
     headerTop,
     footerBottom
    },
    data () {
      return {
        isLong:true, //图片是否放大
        isLong1:false,
        isLong2:false,
        swiper: null,
        // url: '../../common/images/icon8.jpeg',
        srcList: [
          {
            url:require('../../common/images/icon8.jpeg'),
            title:'智慧展厅',
            content:'智慧展厅是企业的另一种形象名片，不单单是反映企业的品牌形象及市场定位，还体现了企业的战略发展方向。'
            },
          {
            url:require('../../common/images/icon9.jpeg'),
            title:'智慧黄金回收',
            content:'行业内企业正在加速推进全国线下布局，整合银行保险、黄金企业、社区物业以及物流企业等资源，建立起覆盖全国的服务网络，积极打造产、供、销一体化新型黄金生态圈。'
            },
          {
            url:require('../../common/images/icon10.jpg'),
            title:'智慧珠宝零售',
            content:'通过一系列先进技术的使用，不仅可以深入挖掘客户需求，还能加速货品周转，为客户打造更加良好的现场体验。'
            },
            

        ],
        screenWidth:0,  //屏幕的宽度
        isActive:false,   //数据驱动 运营赋能图片是否激活
        isActive1:false,   //数据驱动 运营赋能图片是否激活
        isActive2:false,   //数据驱动 运营赋能图片是否激活
      }
    },
   
    mounted() {
      // this.getW()
      this.swiper= new Swiper('.swiper-container', {
        //轮播图swiper相关属性
          speed:3000,
          pagination:'.swiper-pagination',
          // paginationClickable:true,
          // slidesPerView:1,
          // spaceBetween:30,
          loop:true,  //开启循环属性
          autoplay: true,
          effect : 'coverflow',
          centeredSlides: true,
          loopFillGroupWithBlank: true,
          coverflowEffect: {
            rotate: 90,
            stretch: 10,
            depth: 90,
            modifier: 2,
            slideShadows: true
            },
        })
      },
    methods: {
      getW(){
        window.screenWidth = document.body.clientWidth;
      // window.screenHeight = document.body.clientHeight;
      this.screenWidth = window.screenWidth;
      // this.screenHeight = window.screenHeight;
        // if(this.screenWidth<=768){
        //   this.$refs.hand.height=this.screenWidth*0.8
        // }else{
        //   this.$refs.hand.height=100%
        // }
        console.log(this.$refs.hand.clientWidth);
        console.log('+++++',screenWidth);
        console.log(this);

        if(this.screenWidth<=768){
          this.$refs.hand.style.width=this.screenWidth*0.8
        }else{
          this.$refs.hand.style.width=this.screenWidth
        }
      },
       changeActive(index) {
        // $event.currentTarget.className = 'wow bg1 bounceInRight achand';
        console.log('========',index);
        if(index==0){
          this.isLong=true
        }
        if(index==1){
          this.isLong1=true
        }
        if(index==2){
          this.isLong2=true
        }
        
      },
      closeActive(index){
        // $event.currentTarget.className = 'wow bg1 bounceInRight';
        if(index==0){
          this.isLong=false
        }
        if(index==1){
          this.isLong1=false
        }
        if(index==2){
          this.isLong2=false
        }
        
      },

      // 跳转到相应路径
      goto(path) {
        this.$router.replace(path)
        console.log('*******',path);
        },
      // 当前路径
      isCurrent(path) {
        console.log(this.$route.path)
        return this.$route.path === path
      },
      


      // 鼠标移入改变图片状态
      enter(isActive){
        this.isActive = true
      },

    // 鼠标移出恢复图片状态
      leave(isActive){
        this.isActive=false
      },
      // 鼠标移入改变图片状态
      enter1(isActive1){
        this.isActive1 = true
      },

    // 鼠标移出恢复图片状态
      leave1(isActive1){
        this.isActive1=false
      },
      // 鼠标移入改变图片状态
      enter2(isActive2){
        this.isActive2 = true
      },

    // 鼠标移出恢复图片状态
      leave2(isActive2){
        this.isActive2=false
      }
      
      

    },

      
  }
</script>

<style scoped>
   @import './home.css';
   .swiper-container {
        /* width: 100%; */
        height: 100%;
        padding: 15px 55px!important;
        
    }
    .swiper-container .swiper-slide {
        width: 290px;
        height: 150px;
        padding-left: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .btn {
        height: 40px;
        width: 20px;
        background-size: contain;
    }
    .swiper-slide img{
      width: 990px;
      height: 555px;
    }
</style>
