import Vue from "vue";
// 引入VueRouter
import VueRouter from 'vue-router'

//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
    //修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}


import Home from '../pages/home/home.vue'
import productCenter from '../pages/productCenter/productCenter.vue'
import solution from '../pages/solution/solution.vue'
import service from '../pages/service/service.vue'
import news from '../pages/news/news.vue'
import aboutUs from '../pages/aboutUs/aboutUs.vue'

// 使用VueRouter插件
Vue.use(VueRouter)
export default new VueRouter({
    // mode: 'history',
    routes: [{
            path: '/',
            redirect: '/home'
        },

        {
            path: '/home',
            component: Home,
        },
        {
            path: '/productCenter',
            component: productCenter,
        },
        {
            path: '/solution',
            component: solution,
        },
        {
            path: '/service',
            component: service,
        },
        {
            path: '/news',
            component: news,
        },
        {
            path: '/aboutUs',
            component: aboutUs,
        },
    ]
})