<template>
  <div>
    <headerTop :path="'/aboutUs'" title="关于我们"/>

    <!-- 通用banner -->
    <div class="com_ban">
      <img src="../../common/images/icon16.jpg" class="wow fadeIn" style="visibility: visible;">
      <div class="container">
        <p class="wow fadeInUp" style="visibility: visible;">关于我们</p>
        <p class="wow fadeInUp" data-wow-delay=".5s" style="visibility: visible; animation-delay: 0.5s;"></p>
        <ul>                      
            <li class="wow fadeInUp  b_active" data-wow-delay="0s" style="visibility: visible; animation-delay: 0s;"><a href="#">公司介绍</a></li>                                
        </ul>
      </div>
    </div>

    <!-- 小导航 -->
    <div class="snav">
      <div class="container  wow fadeInUp" style="visibility: visible;">
        <img src="../../common/images/icon11.png">
        <ul>
          <a href="http://cloud.ngtcszjys.com/">首页</a> &gt;&gt; <a href="#" title="关于我们">关于我们</a> &gt;&gt;&nbsp;&nbsp;
          <a href="#" title="公司介绍">公司介绍</a>
        </ul>
      </div>   


    </div>

    <!-- 公司描述 -->
    <div class="desco">
      <div class="decontainer">
        <div class="descmain"><b class="wow fadeInUp" style="visibility: visible;">珠宝国检集团研究所(深圳)有限公司</b>
          <div class="num">
            <ul>
              <li class="wow fadeInUp" style="visibility: visible;">
                <div class="move">
                  <span id="box">985</span>科技院校
                </div>
                <p>
                  团队成员
                </p>
              </li>
              <li class="wow fadeInUp" style="visibility: visible;">
                <div class="move">
                  <span id="box1">300</span>+
                </div>
                <p>
                研发人员
                </p>
              </li>
              <li class="wow fadeInUp" style="visibility: visible;">
                <div class="move">
                  <span id="box2">50</span>+
                </div>
                <p>
                博士学位
                </p>
              </li>
              <li class="wow fadeInUp" style="visibility: visible;">
                <div class="move">
                  <span id="box3">156</span>+
                </div>
                <p>
                硕士学位
                </p>
              </li>
            </ul> 
          </div>
          <p class="wow fadeInUp" style="visibility: visible;">
            珠宝玉石首饰国检集团深圳研究所有限公司（以下简称“研究所公司”），前身为自然资源部珠宝玉石首饰管理中心深圳珠宝研究所，于2022年3月转企改制，为珠宝玉石首饰国检集团全资子公司。<br>
            研究所公司主要从事珠宝玉石首饰和首饰贵金属材料学科研究、仪器研发制造、软件系统集成和计量测试服务。目前已研发出DDO 3210N珠宝首饰观察鉴定仪、MIP 100多功能光致发光光谱仪、称重照相一体机等多款仪器设备，为珠宝质检、技术标准、行业规范提供强有力的技术支撑。<br>
            研究所公司为全国标准样品技术委员会有色金属分委员会认可的有色金属标样定点研制和定点销售单位，也是国内最主要的贵金属标准样品研制者和供应者之一。
          </p>
          <p class="wow fadeInUp" style="visibility: visible;">
          在此，珠宝国检集团研究所(深圳)有限公司愿携手全国各地珠宝检测共同推进产业数字化应用，全面落地推进珠宝行业数字化建设！
          </p>  
        </div>
        <img src="../../common/images/icon19.jpg" class="wow fadeInRight" style="visibility: visible;">
  
      </div>
    </div>

    <!-- 企业使命 企业愿景 -->
    <div class="com_aim">
      <div class="com_container">
          <ul>
            <li class="wow fadeInLeft" style="visibility: visible;">
              <p></p>
              <p>科技改变生活</p>
              <p>企业使命</p><img src="../../common/images/icon20.png">
            </li>
            <li class="wow fadeInLeft" style="visibility: visible;">
              <p></p>
              <p>用户至上，诚信为本</p>
              <p>企业愿景</p><img src="../../common/images/icon21.png">
            </li>
          </ul>
      </div>
    </div>



    <footerBottom/>
  </div>
</template>

<script>
  import headerTop from '../../components/headerTop/headerTop.vue'
  import footerBottom from '../../components/footerBottom/footerBottom.vue'
  export default {
    components:{
      headerTop,
      footerBottom
    }
  }
</script>

<style scoped>
  @import './aboutUs.css';
</style>