<template>
  <!-- footer部分 -->
    <div class="footer">
      <div class="ftop">
        <div class="container">
          <p class="wow fadeInLeft" style="visibility: visible; animation-name: fadeInLeft;"><strong>携手NGTC，走向未来</strong> _ JOIN HANDS WITH THINK TANKS FOR THE FUTURE</p>				<!--<a href="/about" class="wow fadeInRight">联系我们<img src="/public/style/new/img/msg.png " /></a>-->
        </div>
      </div>

      <div class="fbot">
        <div class="container">
          <div class="fbotbot">
            <div class="ewm wow fadeInLeft" style="visibility: visible; animation-name: fadeInLeft;">
              <img src="../../common/images/icon8.png" style="width:130px;">
              <p>珠宝国检集团公众号</p>
            </div>
            <img src="../../common/images/icon7.png" class="wow fadeInUp" style="visibility: visible; animation-name: fadeInUp;">
            <ul class="botlist wow fadeInRight" style="visibility: visible; animation-name: fadeInRight;">
              <li class="rightInfo">
                <p>关于我们</p>
                <ul>
                  <li><a href="#">公司介绍</a></li>
                </ul>
              </li>
              <li class="rightInfo">
                <p>新闻资讯</p>
                <ul>
                  <li><a href="#">待添加</a></li>
                </ul>
              </li>
              <li class="rightInfo">
                <p>产品中心</p>
                <ul>
                  <li><a href="#">智慧硬件</a></li>
                  <li><a href="#">软件系统</a></li>
                  <li><a href="#">服务赋能</a></li>
                </ul>
              </li>
              <li>
                <p>联系我们</p>
                <ul class=" list-paddingleft-2">
                  <li><p><a>电话：待完善</a></p></li>
                  <li><p><a>地址：深圳市罗湖区翠竹街道翠平社区贝丽南路4号</a></p><p><a style="word-spacing: 0px;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 国家珠宝检测中心大厦十三层</a></p>
                  </li>
                </ul>						
              </li>
            </ul>
          </div>
          <p style="text-align: center; line-height: 30px;">
            <a href="https://beian.miit.gov.cn/" target="_blank" style="white-space: normal; box-sizing: border-box; margin: 0px; padding: 0px; font-family: &quot;Microsoft YaHei&quot;, Arial, Helvetica, sans-serif; font-size: 14.08px; text-decoration: underline; color: rgb(0, 0, 0);"><span style="box-sizing: border-box; margin: 0px; padding: 0px; font-family: &quot;Microsoft YaHei&quot;, Arial, Helvetica, sans-serif; font-size: 14.08px; text-decoration: underline; color: rgb(0, 0, 0);">京ICP备13027169号</span>
            </a>
          </p>				
        </div>
      </div>
	  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
  /* footer部分 */
 
 .ftop {
     padding: 45px 0;
     background: linear-gradient(to left, #01B3F3, #416EE1);
     color: #fff;
     font-size: 16px;
     font-weight: 700;
     /* color: #01B3F3; */
 }
 
 .footer .container {
     width: 1200px;
     margin: 0 auto;
 }
 
 .ftop>div {
     display: flex;
     justify-content: space-between;
     align-items: center;
 }
 
 .fbot {
     background-color: #3E73E3;
     padding: 30px 0;
     color: #fff;
 }
 
 .fbotbot {
     display: flex;
     justify-content: space-between;
     align-items: center;
     text-align: center;
     font-size: 16px;
 }
 
 .botlist>li {
     display: inline-block;
     /* display: flex;
     justify-content: space-between; */
     vertical-align: top;
     text-align: left;
     line-height: 2;
 }
 
 .botlist>li:nth-child(n+2) {
     margin-left: 40px;
 }
 
 .botlist p {
     font-size: 16px;
     font-weight: bold;
 }
 
 .ewm {
     line-height: 3;
 }
 
 .rightInfo ul li a {
     color: #fff !important;
 }
</style>