var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"headerTop"},[_vm._m(0),_c('div',{staticClass:"rightNav"},[_c('ul',[_c('li',{on:{"click":function($event){return _vm.goto('/home')}}},[_c('a',{staticClass:"first",class:{active: _vm.isCurrent('/home')}},[_vm._v("首页")]),_c('span')]),_c('li',{on:{"click":function($event){return _vm.goto('/productCenter')}}},[_c('a',{staticClass:"first",class:{active: _vm.isCurrent('/productCenter')}},[_vm._v("产品中心")]),_vm._m(1),_c('span')]),_c('li',{on:{"click":function($event){return _vm.goto('/solution')}}},[_c('a',{staticClass:"first",class:{active: _vm.isCurrent('/solution')}},[_vm._v("解决方案")]),_c('span'),_vm._m(2)]),_c('li',{on:{"click":function($event){return _vm.goto('/service')}}},[_c('a',{staticClass:"first",class:{active: _vm.isCurrent('/service')}},[_vm._v("服务案例")]),_c('span'),_vm._m(3)]),_c('li',{on:{"click":function($event){return _vm.goto('/news')}}},[_c('a',{staticClass:"first",class:{active: _vm.isCurrent('/news')}},[_vm._v("新闻资讯")]),_c('span'),_vm._m(4)]),_c('li',{on:{"click":function($event){return _vm.goto('/aboutUs')}}},[_c('a',{staticClass:"first",class:{active: _vm.isCurrent('/aboutUs')}},[_vm._v("关于我们")]),_vm._m(5)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"logo"},[_c('a',{attrs:{"href":"#"}},[_c('img',{attrs:{"src":require("../../common/images/logo.png"),"alt":"珠宝玉石首饰国检集团深圳研究所有限公司","srcset":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dropitempc",staticStyle:{"height":"140px","padding-top":"10px","margin-top":"0px","padding-bottom":"0px","margin-bottom":"0px"}},[_c('ul',[_c('li',[_c('a',{attrs:{"href":"#"}},[_vm._v("智慧硬件")])]),_c('li',[_c('a',{attrs:{"href":"#"}},[_vm._v("软件系统")])]),_c('li',[_c('a',{attrs:{"href":"#"}},[_vm._v("服务赋能")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dropitempc",staticStyle:{"height":"224px","padding-top":"10px","margin-top":"0px","padding-bottom":"0px","margin-bottom":"0px"}},[_c('ul',[_c('li',[_c('a',{attrs:{"href":"#"}},[_vm._v("智慧珠宝检测")])]),_c('li',[_c('a',{attrs:{"href":"#"}},[_vm._v("珠宝智慧零售")])]),_c('li',[_c('a',{attrs:{"href":"#"}},[_vm._v("智慧展厅")])]),_c('li',[_c('a',{attrs:{"href":"#"}},[_vm._v("智慧工厂")])]),_c('li',[_c('a',{attrs:{"href":"#"}},[_vm._v("智慧黄金回收")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dropitempc",staticStyle:{"height":"56px","padding-top":"10px","margin-top":"0px","padding-bottom":"0px","margin-bottom":"0px"}},[_c('ul',[_c('li',[_c('a',{attrs:{"href":"#"}},[_vm._v("即将上线")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dropitempc",staticStyle:{"height":"56px","padding-top":"10px","margin-top":"0px","padding-bottom":"0px","margin-bottom":"0px"}},[_c('ul',[_c('li',[_c('a',{attrs:{"href":"#"}},[_vm._v("待添加")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dropitempc",staticStyle:{"height":"56px","padding-top":"10px","margin-top":"0px","padding-bottom":"0px","margin-bottom":"0px"}},[_c('ul',[_c('li',[_c('a',{attrs:{"href":"#"}},[_vm._v("公司介绍")])])])])
}]

export { render, staticRenderFns }