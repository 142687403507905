<template>
  <div>
    <headerTop :path="'/solution'" title="解决方案"/>

    <!-- 通用banner -->
    <div class="com_ban">
      <img src="../../common/images/icon12.jpg" class="wow fadeIn" style="visibility: visible;">
      <div class="container">
        <p class="wow fadeInUp" style="visibility: visible;">解决方案</p>
        <p class="wow fadeInUp" data-wow-delay=".5s" style="visibility: visible; animation-delay: 0.5s;"></p>
        <ul>             
          <li :class="{b_active: show==0}" @click="goTo(0)" class="wow fadeInUp" data-wow-delay="0s" style="visibility: visible; animation-delay: 0s;"><a href="#">智慧珠宝检测</a></li>            
          <li :class="{b_active: show==1}" @click="goTo(1)" class="wow fadeInUp" data-wow-delay="0s" style="visibility: visible; animation-delay: 0s;"><a href="#">珠宝智慧零售</a></li>          
          <li :class="{b_active: show==2}" @click="goTo(2)" class="wow fadeInUp" data-wow-delay="0s" style="visibility: visible; animation-delay: 0s;"><a href="#">智慧展厅</a></li>       
          <li :class="{b_active: show==3}" @click="goTo(3)" class="wow fadeInUp" data-wow-delay="0s" style="visibility: visible; animation-delay: 0s;"><a href="#">智慧工厂</a></li>            
          <li :class="{b_active: show==4}" @click="goTo(4)" class="wow fadeInUp" data-wow-delay="0s" style="visibility: visible; animation-delay: 0s;"><a href="#">智慧黄金回收</a></li>
        </ul>
      </div>
    </div>

    <!-- 小导航 -->
    <div class="snav">
    <div class="container  wow fadeInUp" style="visibility: visible;">
      <img src="../../common/images/icon11.png">
      <ul>
        <a href="#">首页</a> &gt;&gt; <a href="#" title="解决方案">解决方案</a> &gt;&gt;&nbsp;&nbsp;
        <a href="#" title="智慧珠宝检测">智慧珠宝检测</a>
      </ul>
    </div>
    </div>

    <!-- 零售行业痛点 -->
    <div class="s1pain">
      <div class="container">
          <p class="bigtit wow fadeInUp" style="visibility: visible;"> 零售行业痛点</p>
          <ul>
            <li class="wow fadeInUp" style="visibility: visible; animation-name: fadeInUp;">
              <img src="../../common/images/icon17.png">
                <p> 运营效率相对较低</p>
                <p></p>
                <div style="text-align:center;">货品高价值需要更好的运营效率</div>
                <div style="text-align:center;">非标准化货品结构决定数据采集难度大</div><p></p>
            </li>
              <li class="wow fadeInUp" style="visibility: visible; animation-name: fadeInUp;">
                <img src="../../common/images/icon18.png">
                <p> 门店体验&amp;获客成本</p>
                <p></p>
                <div style="text-align:center;">低频、高价值、产品非标准化，线下销售为核心</div>
                <div style="text-align:center;">目前门店体验都是单一，获客成本较高，客户转化率低</div>
                <div style="text-align:center;">线上&amp;线下结合能力很弱，客户留存率低</div>
                <div style="text-align:center;"><br>
                </div><p></p>
              </li>
          </ul>
      </div>
    </div>


    <footerBottom/>
  </div>
</template>

<script>
  import headerTop from '../../components/headerTop/headerTop.vue'
  import footerBottom from '../../components/footerBottom/footerBottom.vue'
  export default {
    components:{
      headerTop,
      footerBottom
    },
    data() {
      return {
        show:0, //0显示智慧珠宝检测，1显示珠宝智慧零售，2显示智慧展厅，3显示智慧工厂，4显示智慧黄金回收
      }
    },

    methods: {
      goTo(index){
        if(index == 0){
          this.show = 0
        }
        if(index == 1){
          this.show = 1
        }
        if(index == 2){
          this.show = 2
        }
        if(index == 3){
          this.show = 3
        }
        if(index == 4){
          this.show = 4
        }
       
      }
    },
  }
</script>

<style scoped>
  @import './solution.css';
</style>